<template>
	<article-wrapper
			:progressColor="`#0096D6`">

			<template v-slot:hero>
				<hero class="csr__hero">
					<figure>Diversity & inclusion</figure>
					<h1>Evolving our Employee <br>Resource Groups</h1>
					<p>Employee Resource Groups (ERGs) represent one of the most vibrant centers of activity to foster diversity, inclusion and understanding across DTCC. </p>
				</hero>
			</template>

			<template v-slot:body>
				<div class="csr__body">
					<div class="body-columns--small">
						<p>In addition to providing opportunities for professional development and growth, our Employee Resource Groups (ERGs) work in partnership with senior leadership to address workforce policies and serve as incubators of innovative solutions that drive positive change.   </p>

						<p>Prior to 2020, we undertook a comprehensive strategic review of our ERGs, with a primary focus on expanding their efforts of building stronger communities. Our aim was for the ERGs to become more closely aligned to DTCC’s diversity and inclusion strategy and better positioned to influence company policy and workplace culture.   </p>
					</div>
				</div>

				<div class="body-quote body-quote--1">
					<div class="body-quote__before"></div>
					<div class="body-quote__content body-quote__content--right">
						<div class="body-quote__quote">
							“Our  ERGs  continue to  make  important  contributions  supporting our strategy and  fostering  a more inclusive  work place  culture, but just as important, they serve a critical role in training  our future leaders. They remain  a powerful differentiator for DTCC, support  employee retention and  help in recruiting  high- quality,  diverse talent.”
							<div class="body-quote__credit">— <strong>Alice  Avellanet</strong>,  Associate Director, Diverse Talent Management & Advancement </div>
						</div>
					</div>
					<div class="body-quote__after"></div>
				</div>

				<div class="csr__body">
					<div class="body-columns">
						<div class="body-columns__content">
							<h2 style="margin-top: 0;">Reinvigorating <br><span>our ERGs </span></h2>
							<p>In 2020, we brought this vision to life by reframing the ERG governance model and leadership structure to align with industry standards. We also refreshed our ERGs with revised missions, new guidelines and toolkits and began building best practices. As part of this, we established stronger partnerships with our Corporate Social Responsibility, Learning & Development and Talent Acquisition teams to enhance community involvement, recruitment and retention for all employees. Our ERGs are now closely aligned to DTCC’s strategic  priorities, corporate values and our diversity and inclusion strategy. In addition, ERG goals are tied to our leadership competency model. By taking these actions, we enhanced the value ERGs bring to the organization as influencers of our culture and business.  </p>
						</div>

						<div class="body-columns__images">
							<div class="body-box">
								<h3><img src="~@/assets/csr/ergIcon1.svg">Connecting Remotely</h3>
								<p>Remote working did not deter the ERGs in 2020. In fact, their contributions proved invaluable by bringing our colleagues together and fostering community throughout the organization.  Thanks to their efforts and other work across the organization, our people  learned to collaborate more, we increased awareness of cultural issues and we tackled challenging issues like societal racism and the impact of the pandemic on our physical and mental health.   </p>
							</div>
							<div class="body-box">
								<h3><img src="~@/assets/csr/ergIcon2.svg">Prioritizing Allyship</h3>
								<p>Looking back, the ERGs embraced our 2020 theme — “Year of Being  An  Ally” — and make a positive impact on the organization and our global team. </p>
							</div>
						</div>
					</div>

					<h2>Our ERGs <span>At A Glance</span></h2>

					<div class="erg-boxes">
						<div class="erg-boxes__box">
							<img src="~@/assets/csr/ergLogo1@2x.jpg" width="78">
							<p>We empower the Asian community at DTCC by providing opportunities for professional development and cultural awareness in support of our diverse community, by uniting our voices and embracing our differences.</p>
						</div>

						<div class="erg-boxes__box">
							<img src="~@/assets/csr/ergLogo2@2x.jpg" width="78">
							<p>Black Organizers &<br> Leaders of DTCC</p>
							<h3>Bold</h3>
							<h4>393 Members</h4>
						</div>

						<div class="erg-boxes__box">
							<img src="~@/assets/csr/ergLogo3@2x.jpg" width="78">
							<p>Supporting professional development for all career stages</p>
							<h3>Ignite</h3>
							<h4>1092 Members</h4>
						</div>

						<div class="erg-boxes__box">
							<img src="~@/assets/csr/ergLogo4@2x.jpg" width="78">
							<p>Military Veterans <br> & Partners</p>
							<h3>mvp</h3>
							<h4>268 Members</h4>
						</div>

						<div class="erg-boxes__box">
							<img src="~@/assets/csr/ergLogo5@2x.jpg" width="78">
							<p>LGBTQIA <br>Group</p>
							<h3>pride+</h3>
							<h4>260 Members</h4>
						</div>

						<div class="erg-boxes__box">
							<img src="~@/assets/csr/ergLogo6@2x.jpg" width="78">
							<p>Hispanic/ <br>Latinx</p>
							<h3>Unidos</h3>
							<h4>346 Members</h4>
						</div>

						<div class="erg-boxes__box">
							<img src="~@/assets/csr/ergLogo7@2x.jpg" width="78">
							<p>Supporting those with a spectrum <br>of abilities & caregivers</p>
							<h3>Wethrive</h3>
							<h4>166 Members</h4>
						</div>

						<div class="erg-boxes__box">
							<img src="~@/assets/csr/ergLogo8@2x.jpg" width="78">
							<p>Women’s Initiative for <br>Networking & Succes</p>
							<h3>Wins</h3>
							<h4>914 Members</h4>
						</div>
					</div>

					<div class="image-list">

						<div class="image-list__item image-list__item--reverse">
							<div>
								<blockquote style="border: 0; padding: 0; margin-bottom: 1em;">
									“Recognized for the 9th consecutive year by the Human Rights Campaign, we are proud to be considered one of the best places to work for LGBTQ employees. Throughout 2020, we ran an active global program of events, which helped provide person and professional support through guest speakers for events like Coming Out Day and AIDS Day. The much-needed personal and professional support equally educated and inspired through guest speakers for important events like Coming Out Day and World AIDS Day. We look forward to continuing the dialogue and creating an inclusive environment that celebrates our LGBTQ colleagues and allies at DTCC.”
								</blockquote>

								<p>— <strong>Derek West & Kevin Cox</strong>, Co-Chairs of PRIDE+ Employee Resource Group </p>
							</div>
							<img src="~@/assets/csr/ergImg1@2x.jpg" width="360" style="max-width: 360px;" />
						</div>

					</div>
				</div>

			</template>

			<template v-slot:footer>
				<article-next class="csr__next" :image="'csr/bgAllyship@2x.jpg'" :link="{name: 'csr', params: {
					article: 'allyship'}}" target-id="next_article" v-hero-click="`#next_article`">
					<template v-slot:label>Diversity & Inclusion</template>
					<template v-slot:title>The Impact of  Allyship  </template>
				</article-next>
			</template>

		</article-wrapper>
</template>

<script>
import ArticleNext from '@/components/ArticleNext'
import ArticleWrapper from '@/components/ArticleWrapper'
import Hero from '@/components/Hero'
import Boxes from '@/components/Boxes'

export default {
	name: 'ERG',
	components: {
		ArticleNext,
		ArticleWrapper,
		Hero,
		Boxes
	},
}
</script>

<style scoped lang="scss">

.csr {
	&__hero {
		background-image: url('~@/assets/csr/bgERG@2x.jpg');
	}

	.body-quote {

		&--1 {
			background-image: url('~@/assets/csr/ergQuote@2x.jpg');
		}
	}

	.body-box {
		@include media-query-min-width('tablet') {
			width: 340px;
		}

		@include media-query-min-width('desktop') {
			width: 450px;
		}
	}

	.erg-boxes {
		@include gutter('margin-top', 2);
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		@include media-query-min-width('tablet') {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			//grid-template-columns: 1fr auto;
			grid-auto-rows: 1fr;
			grid-gap: 16px;
			margin-bottom: 0;
		}

		@include media-query-min-width('desktop') {
			grid-template-columns: repeat(4, 1fr);
		}

		&__box {
			@include gutter('padding', 1.5);
			background: #fff;
			box-shadow: 0 0 24px rgba(0, 0, 0, .16);
			border-radius: 20px;
			height: 100%;
			margin-bottom: 16px;
			text-align: center;
			width: 100%;
			// display: flex;
			// flex-direction: column;
			// align-items: center;
			// justify-content: space-between;

			@include media-query-min-width('tablet') {
				margin-bottom: 0px;
			}

			p {
				@include gutter('margin-top', .5);
				@include gutter('margin-bottom', .5);
				@include fontSize(14px);
				color: color_('text','light');
				line-height: 1.25;
			}

			h2 {
				margin-top: 0;
			}

			h3 {
				@include fontSize(48px);
				text-transform: uppercase;
				font-weight: bold;
				line-height: 1;
				margin: 0;


				img {
					margin-right: 20px;
				}
			}

			h4 {
				@include fontSize(32px);
				margin: 0;
				font-weight: bold;
				color: color_('primary');
			}

		}
	}
}

</style>
